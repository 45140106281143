var Common = (function ($) {

   var settings = {
   };

   var s = settings;

   var init = function () {
      //_cacheElements();
      //_bindUIActions();
   };

   var _cacheElements = function() {
   }

   var _bindUIActions = function() {
   }

   var initHomepageSlider = function() {
      var slider = $('#homepage-slider');

      if(!slider.length) {
         return false;
      }

      slider.on('init', function(event, slick){
      });

      slider.not('.slick-initialized').slick({
         infinite: true,
         speed: 300,
         dots: true,
         autoplay: true,
         fade: true,
         cssEase: 'linear',
         autoplaySpeed: 2000
      });
	}

	var initGenericSlider = function() {
      var slider = $('#generic-slider');

      if(!slider.length) {
         return false;
      }

      slider.on('init', function(event, slick){
      });

      slider.not('.slick-initialized').slick({
         infinite: true,
         speed: 300,
         dots: true,
         lazyLoad: 'progressive',
         autoplay: true,
         autoplaySpeed: 2000,
         fade: true,
         cssEase: 'linear'
      });
	}

	var initShare = function() {
	  	$('.js-share').click(function(event) {
	    	var width   = 550,
				height  = 400,
				left    = ($(window).width()  - width)  / 2,
				top     = ($(window).height() - height) / 2,
				url     = this.href,
				network = $(this).data('network'),
				opts    = 'status=1' +
							',width='  + width  +
							',height=' + height +
							',top='    + top    +
							',left='   + left;

	    window.open(url, network, opts);

	    return false;
	  });
	}

   return {
      init: init,
      initHomepageSlider: initHomepageSlider,
      initGenericSlider: initGenericSlider,
      initShare: initShare
   };

})(jQuery);
