var ProductPage = (function ($) {

	var settings = {};

	s = settings;

  	var init = function () {
		_bindUIActions();

  		_productSlider();
      _relatedTabs();
	};

	var _bindUIActions = function() {
	};

	var _productSlider = function() {
      var slider = $('#product-slider');

      if(!slider.length) {
         return false;
      }

      slider.on('init', function(event, slick){
         // Slider initialised
      });

      slider.not('.slick-initialized').slick({
         slidesToShow: 3,
         responsive: [
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 2
               }
            },
            {
               breakpoint: 480,
               settings: {
                  slidesToShow: 1
               }
            }
         ]
      });
	}

	var _relatedTabs = function () {
		relatedTabs = $('#product-view-tabs').children().first();
      // Tabs
      relatedTabs.next().addClass('active');
      // Navigation
      relatedTabs.children().first().addClass('active')
	};

	return {
		init: init
	};

})(jQuery);
