var Authentication = (function ($) {

   var settings = {
      loggedIn: false
   };

   var s = settings;

   var init = function () {
      _cacheElements();
      _bindUIActions();
      _initializeAuthenticationForm();
   };

   var _cacheElements = function() {
      s.module = $('#authentication');
      s.form = $('#authentication form');
   }

   var _bindUIActions = function() {

      // window.Parsley.on('field:error', function (fieldInstance) {
      //     Messages.new(ParsleyUI.getErrorsMessages(fieldInstance));
      // });

      if(s.form.length) {
         s.form.on('submit', function (event) { _submitForm(event) });
      }
   }

   var _initializeAuthenticationForm = function() {
      if(s.form.length) {
         s.form.parsley();
         s.ajaxUrl = s.form.attr('action');
      }
   }

   var _getUrl = function() {
      var ajaxUrl = s.form.attr('action');

      return ajaxUrl;
   }

   var _getData = function () {
      var formFields = s.form.serialize();

      return formFields;
   };

   var _submitForm = function(event) {
      event.preventDefault(event);

      if(Page.settings.loading || !s.form.parsley().isValid()) {
         return false;
      }

      Page.loader(true, true);

      // Send data
      var ajaxAuthentication = jQuery.ajax({
         type: 'POST',
         url: _getUrl(),
         data: {
            data : _getData()
         },
      });

      // Get data from a finished request
      ajaxAuthentication.done(function(response) {
         // Parse JSON data into jQuery object
         response = JSON.parse(response);

         if(response.result == 'success') {

            if(response.loggedin) {
               Authentication.s.loggedIn = true;

               if(Checkout.s.checkingOut) {
                  Checkout.start();
                  return false;
               }
            }

            s.module.replaceWith(response.html);
            init();
         }
         else {
            Messages.new(response.message, response.result);
         }

         Page.loader(true, true);
      });

      // Error on ajax call
      ajaxAuthentication.fail(function(jqXHR, textStatus, errorThrown) {
         console.log(response);
         Page.loader(true, true);
      });
   }

   return {
      init: init,
      s: settings
   };

})(jQuery);