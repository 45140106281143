var Cart = (function ($) {

   var settings = {
   };

   var s = settings;

   var init = function () {
      _cacheElements();
      _bindUIActions();
   };

   var _cacheElements = function() {
      s.module = $('#cart');
      s.form = $('#product_addtocart_form');
      s.loader = $(".animateLoader");
   }

   var _bindUIActions = function() {
      $('.add-to-box').on('click', 'button', _addItem);
      $('#sidebar-modules').on('click','.remove', _removeItem);
   }

   var _updateCartQty = function(qty) {
      $('.toplinks .cart-toplink .qty').text(qty);
   }

   var _updateCartContent = function (content) {
      s.module.replaceWith(content);
      s.module = $('#cart'); // Recache
      s.module.addClass('active');
      _bindUIActions();
   }

   var _addItem = function() {
      var ajaxUrl = s.form.attr('action');
      var data = s.form.serialize();

      Page.switchToModule('cart');
      _cartController(ajaxUrl, data);

      event.preventDefault();
   }

   var _removeItem = function() {
      var ajaxUrl = $(this).attr('href');

      _cartController(ajaxUrl);

      event.preventDefault();
   }

   var _cartController = function(ajaxUrl, data) {

      if(Page.settings.loading) {
         return false;
      }

      Page.loader(true, true);

      // Send data
      var ajaxCall = jQuery.ajax({
         type: 'POST',
         url: ajaxUrl,
         data: {
            data : data
         },
      });

      // Get data from a finished request
      ajaxCall.done(function(response) {
         // Parse JSON data into jQuery object
         response = JSON.parse(response);

         if (response.result == 'success') {
            _updateCartQty(response.qty);
            _updateCartContent(response.sidebar);
            fbq('track', 'AddToCart');
            document.dispatchEvent(new Event('addToCartSuccess'))

            if(!Page.settings.sidebarOpen) {
               Page.toggleSidebar(null, 'cart');
            }
         }

         if (response.result == 'error') {
            if(!Page.settings.sidebarOpen) {
               Page.toggleSidebar(null, 'cart');
            }
         }

         if(response.message.length) {
            Messages.new(response.message, response.result);
         }

         Page.loader(true, true);
      });

      // Error on ajax call
      ajaxCall.fail(function(jqXHR, textStatus, errorThrown) {
         Page.loader(true, true);
      });
   }

   return {
      init: init
   };

})(jQuery);
