var Messages = (function ($) {

	var settings = {};

  	var init = function () {
		_bindUIActions();
		_subscribeEvents();
	};

	var _bindUIActions = function() {
		$('.messages').on('click', 'li', _removeMessage);
	}

	var _subscribeEvents = function() {
		$.subscribe('messages/new', newMessage());
	}

	var _removeMessage = function(message) {

   	if(!message.jquery) {
   		message = $(this);
   	}

      if(message.length) {
         message.addClass('remove');

         message.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
            function(e) {
               message.remove();
            });
      }

      return false;
   }

   var newMessage = function(message, type) {

   	var container = $('.sidebar .messages');

   	if ( message !== undefined ) {

         // if(message instanceof Array) {
         //    $.each( message, function( index, message ){
         //       message = $('<li class="'+ type +'-msg cart-msg">' + message + '</li>').appendTo(container);
         //    });
         // }

         message = $('<li class="'+ type +'-msg cart-msg">' + message + '</li>').appendTo(container);

   		setTimeout(function () {
	         _removeMessage(message);
	      }, 5000);
   	}

   }

	return {
		init: init,
		new: newMessage
	};

})(jQuery);