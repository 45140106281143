var Page = (function ($, Page) {

   var settings = {
      loading: false,
      sidebarOpen: false,
      menuOpen: false,
      isSearching: false,
      didScroll: false,
      lastScrollTop: 0,
      delta: 5,
      module: false
   };

   var s = settings;

   var init = function () {
      _cacheElements();
      _bindUIActions();

      loader();
   };

   var _cacheElements = function() {
      s.window = $(window);
      s.body = $('body');
      s.loader = $('.animateLoader');
   }

   var _bindUIActions = function() {
      s.window.bind('beforeunload', function() { loader(); });

      s.window.load(function() { loader(); });

      s.window.scroll(function(e) { _scroll(); });

      // Toggle mobile menu
      $(".menu-toggle").on('click', toggleMenu);

      // Toggle mobile submenu
      $(".mobile-submenu-toggle").on('click', toggleSubmenu);

      $('.sidebar-toggle').on('click', toggleSidebar);

      $('.searchtrigger').on('click', _search);

      $('.close-page-elements').on('click', closePageElements);
  }

   var _pageLoader = function () {
      if(!s.loading) {
         s.body.addClass("app-loading");
      }
      else if(s.loading) {
         s.body.removeClass("app-loading");
      }
   };

   var _sidebarLoader = function(action) {
      if(!s.loading) {
         s.loader.fadeIn("slow");
      }
      else if(s.loading) {
         s.loader.fadeOut("slow");
      }
   }

   var _scroll = function() {
      s.didScroll = true;

      setInterval(function() {
         if (s.didScroll) {
            _hasScrolled();
            s.didScroll = false;
         }
      }, 250);
   }

   var _hasScrolled = function () {
      var st = $(this).scrollTop();
      var navbar = $('#navbar');

      // Make sure they scroll more than delta
      if(Math.abs(s.lastScrollTop - st) <= s.delta)
         return;

      // If they scrolled down and are past the navbar, add class .nav-up.
      // This is necessary so you never see what is "behind" the navbar.
      if (st > s.lastScrollTop && st > navbar.outerHeight()){
         // Scroll Down
         navbar.addClass('navbar-up');
      } else {
         // Scroll Up
         if(st + s.window.height() < $(document).height()) {
            navbar.removeClass('navbar-up');
         }
      }

      s.lastScrollTop = st;
   }

   /**
   * Initiates the search bar
   */
   var _search = function(event) {
      s.body.toggleClass('searching')
      $(".searchbar input").focus();
   }

   var _activateModule = function(action) {

      if(s.module != action) {
         $('#sidebar-modules').children().removeClass('active');
         $('#' + action).addClass('active');

         s.module = action
      }
   }

   /**
    * Public Functions
    */
   var loader = function (page, sidebar) {

      if(page === undefined) {
        page = true;
      }
      if(sidebar === undefined) {
        sidebar = false;
      }

      if(page) {
         _pageLoader();
      }

      if(sidebar) {
         _sidebarLoader();
      }

      s.loading ^= true;
   };

   var toggleMenu = function() {

      s.body.toggleClass('mobile-menu-open');

      s.menuOpen ^= true;
   }

   var toggleSubmenu = function(event) {

      var submenu = $(this).closest(".submenu");

      if(submenu.hasClass('is-open')) {
        submenu.removeClass("is-open");
      }
      else {
         $(this).next().addClass("is-open");
      }
      event.preventDefault();
   }

   /**
    * Toggles the sidebar
    */
   var toggleSidebar = function(event, action) {

      if(!action) {

         action = $(this).data('sidebar-action');

         if(action != 'close') {
            _activateModule(action);
         }
      }

      s.body.toggleClass('sidebar-open');

      s.sidebarOpen ^= true;

      return false;
   }

   var closePageElements = function(event) {

      if(s.menuOpen) {
         toggleMenu();
         s.menuOpen = false;
      }

      if(s.sidebarOpen) {
         toggleSidebar();
         s.sidebarOpen = false;
      }

      if(s.isSearching) {
         _search();
         s.isSearching = false;
      }

      return false;
   }

   var switchToModule = function(module) {
      if(s.module != module) {
         loader(true, true);
         _activateModule(module);
         loader(true, true);
      }
   }

   return {
      init: init,
      settings: settings,
      s: settings,
      loader: loader,
      toggleMenu: toggleMenu,
      toggleSubmenu: toggleSubmenu,
      toggleSidebar: toggleSidebar,
      switchToModule: switchToModule
   };

})(jQuery);
