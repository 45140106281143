var Service = (function ($) {

   var init = function () {
      _bindUIActions();
   };

   var _bindUIActions = function() {
      $('a[href^="#"]').on('click', _scrollTo);
   }

   var _scrollTo = function() {

      var target = $(this.getAttribute('href'));
      if( target.length ) {
         event.preventDefault();
         $('html, body').stop().animate({
            scrollTop: target.offset().top
         }, 1000);
      }
	}

   return {
      init: init
   };

})(jQuery);