var Newsletter = (function () {
    const settings = {
        loading: false,
        subscribed: false,
    };

    const init = function () {
        _initializeFooterForm();
        _bindUIActions();
        _cookieController();
    };

    const _bindUIActions = function () {
        document.addEventListener('click', function (event) {
            if (event.target.classList.contains('newsletter-toggle')) {
                _openNewsletterForm(event);
            }
        });

        const form = document.querySelector('#newsletter-form');
        if (form) {
            form.addEventListener('submit', function (event) {
                event.preventDefault();
                if (form.checkValidity()) {
                    _submitForm();
                }
            });
        }
    };

    const _initializeFooterForm = function () {
        // Config for Parsley or other validation library can be initialized here if needed
        const form = document.querySelector('#newsletter-form');
        if (form) {
            // Add custom validation configuration here if necessary
        }
    };

    const _cookieController = function () {
        let views = parseInt(localStorage.getItem('userViews') || '0') + 1;
        localStorage.setItem('userViews', views);

        if (views === 3 && localStorage.getItem('subscribed') !== 'true') {
            setTimeout(_openNewsletterForm, 2000);
        }
    };

    const _openNewsletterForm = function (event) {
        const newsletter = document.getElementById('newsletter');
        if (newsletter) {
            newsletter.classList.toggle('open');
        }
    };

    const _getUrl = function () {
        const form = document.querySelector('#newsletter-form');
        return form ? form.action : '';
    };

    const _getData = function () {
        const form = document.querySelector('#newsletter-form');
        if (form) {
            const formData = new FormData(form);
            return new URLSearchParams(formData).toString();
        }
        return '';
    };

    const _submitForm = async function () {
        console.log('submit form');
        try {
            Page.loader(true);

            const response = await fetch(_getUrl(), {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: _getData(),
            });
            const result = await response.json();

            const messageContainer = document.querySelector('.newsletter-parsley-errors');
            if (result.result === "error" && messageContainer) {
                messageContainer.innerHTML = result.message;
            } else if (result.result === "success") {
                const container = document.querySelector('#newsletter .container');
                if (container) {
                    container.innerHTML = `<p class="result">${result.message}</p>`;
                }
                localStorage.setItem('subscribed', 'true');
            }
        } catch (error) {
            console.error('Newsletter subscription error:', error);
        } finally {
            Page.loader(false);
        }
    };

    const subscribe = function () {
        // Public method
    };

    return {
        init,
        s: settings,
        subscribe,
    };
})();