var Checkout = (function ($) {

   var settings = {
      checkingOut: false
   };

   var s = settings;

   var init = function () {
      //_cacheElements();
      _bindUIActions();
   };

   var _cacheElements = function() {
   }

   var _bindUIActions = function() {
      $('#sidebar-modules').on('click', '.initiate-checkout', startCheckout);
   }

   var startCheckout = function() {
      s.checkingOut = true;

      console.log('checkout');
      fbq('track', 'InitiateCheckout');
      document.dispatchEvent(new Event('initiateCheckout'));
      window.location.href = "/onestepcheckout";
   }

   return {
      init: init,
      start: startCheckout,
      s: settings
   };

})(jQuery);
