var Tabs = (function ($) {

	var settings = {};

	s = settings;

  	var init = function () {
		$('.tabs').each(function() {
         var $this = $(this);
         // this element has already been initialized
         if($this.data('isTabs')) {
            return true;
         }
         // mark element as initialized
         $this.data('isTabs', true);
         new Tabs($this);
      });
	};

   var Tabs = function($el) {
      this.$el = $el;
      this.$nav = this.$el.find('> ul.tab-nav > li');
      this.$content = this.$el.children('.tab-content');

      var scope = this;

      // listen for click event on tab nav and custom gumby set event
      this.$nav.children('a').on('click', function(e) {
         e.preventDefault();
         scope.click($(this));
      });

      // listen for gumby.set value for dynamically set tabs
      this.$el.on('gumby.set', function(e, index) {
         scope.set(e, index);
      });
   }

   // handle tab nav click event
   Tabs.prototype.click = function($this) {
      // index of item to activate
      var index = $this.parent().index();

      if(this.$nav.eq(index).add(this.$content.eq(index)).hasClass('active')) {
         return;
      }

      // deactivate other tab navigation and content
      this.$nav.add(this.$content).removeClass('active');

      // activate this tab nav link and content
      this.$nav.eq(index).add(this.$content.eq(index)).addClass('active');

      // trigger gumby.change event and pass current active tab index
      this.$el.trigger('onChange', index);
   };

   // set specific tab
   Tabs.prototype.set = function(e, index) {
      this.$nav.eq(index).find('a').trigger('click');
   };

	return {
		init: init
	};

})(jQuery);